@import url('https://fonts.cdnfonts.com/css/proxima-nova-2');

:root {
    --main-color: #4E4E4E;
    --title-color: #1B1B1B;
    --white-color: #FFFFFF;
    --black-color: #000000;
    --custom-color-1: #ED5A2F;
    --custom-color-2: #540022;
    --custom-color-3: #292F36;
    --light-gray-color: #F0F0F0;
    --gray-color: #777777;
    --light-red-color: #FA0F00;
    --red-color: #860B0C;
    --main-red-color: #ED5A2F;
    --pink-color: #FEDFDD;
    --dark-blue: #292F36;
    --main-light-blue-color: #FFF;
    --main-dark-blue-color: #0061FF;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

input,
button,
textarea,
select {
    font: inherit;
}

html,
body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    color: var(--main-color);
    height: 100%;
    width: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
}

.flex-container {
    display: flex;
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.align-center {
    align-items: center !important;
    justify-content: center !important;
}

.align-top {
    align-items: center !important;
    justify-content: flex-start !important;
}

.justify {
    justify-content: space-between;
    width: 100%;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

p {
    margin: 0;
}

.custom-color-2 {
    color: var(--custom-color-2);
}

.custom-color-3 {
    color: var(--custom-color-3);
}

.screen-wrapper {
    margin: 50px auto;
    margin-bottom: 30px;
}

.screen-wrapper h1 {
    color: #161616;
    font-family: "scale-variable", sans-serif;
    font-variation-settings: "wght" 400, "wdth" 100;
    font-size: 42px;
    font-weight: 400;
    line-height: 57.54px;


}

.screen-wrapper p.subtitle {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: #777777;
    margin-top: 10px;
}

.screen-wrapper img.main-image {
    margin-bottom: 50px;
}

.pronto {
    margin-top: 88px;

}

.btn {
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    color: #121212;
    background-color: #F0F0F0;
    border-radius: 10px;
    height: 60px;
    border: none;
    min-width: 235px;
    max-width: 100%;
    cursor: pointer;

    img {
        padding-right: 10px;
    }

    &:hover {
        background: #DADADA;
    }

    &.disabled {
        background: #DADADA;
    }

    &.disabled {
        cursor: default;
    }

    &.undo-btn,
    .reload-btn {
        background: #0061FF;
        color: var(--white-color);

        &:hover {
            color: #0061FF;
            background: #FFF;

            img.hover {
                display: block;
            }

            img.non-hover {
                display: none;
            }
        }

        &.disabled {
            background: #DADADA;
        }

        &.disabled {
            cursor: default;
        }
    }

}

.undo-btn,
.reload-btn {
    img.hover {
        display: none;
    }

    align-self: center !important;
    background: #0061FF;
    color: var(--white-color);

    &:hover {
        color: #0061FF;
        background: #FFF;

        img.hover {
            display: block;
        }

        img.non-hover {
            display: none;
        }
    }

    &.disabled {
        background: #DADADA;
    }

    &.disabled {
        cursor: default;
    }
}

small {
    margin-top: 15px;
    font-family: Arial;
    font-size: 18px;
    color: #444444;
    line-height: 23.44px;


}

.pronto-error {
    padding: 50px 200px;
    gap: 20px;
    border: 2px solid #0061FF;
    background: #FFF;
    border-radius: 10px;
}

.error-title {
    color: #2E2E2E;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.03em;
    text-align: center;
}

.pronto__loading {
    width: 100%;
    max-width: 900px;
    box-sizing: border-box;
    gap: 50px;
    padding: 50px;
    border: 1.5px dashed #B3B3B3;
    background-color: transparent;
    justify-content: space-between;
    height: 550px;

    button {
        align-self: flex-end;
        width: 220px;

        img.hover {
            display: none;
        }

        &.progress-button {
            background: #0061FF;
            color: var(--white-color);

            &:hover {
                color: #0061FF;
                background: #FFF;

                img.hover {
                    display: block;
                }

                img.non-hover {
                    display: none;
                }
            }

            &.disabled {
                background: #DADADA;
            }

            &.disabled {
                cursor: default;
            }
        }
    }

    div.line-item {
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        vertical-align: middle;
        line-height: 50px;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
    }

    .line-btns {
        justify-content: flex-end;
    }

    .tbl-file-title {
        padding: 0px 10px;
        height: 50px;
        overflow: hidden;

        img {
            position: relative;
            top: 10px;
            left: 0px;
            margin-right: 10px;
        }
    }

    .tbl-icon {
        padding: 0px 20px;
        min-width: 200px;

        img {
            margin-right: 10px;
            width: 30px;
        }
    }

    .tbl-type {
        margin-left: 50px;

        select {
            padding: 3px 7px;
            text-transform: uppercase;
        }

        span {
            text-transform: uppercase;
        }
    }

    .tbl-status {
        margin: 0px 20px;

        >span {
            font-weight: 500;
            font-size: 16px;
            color: var(--white-color);
            background: var(--custom-color-1);
            border-radius: 10px;
            text-align: center;
            padding: 10px 12px;
        }
    }

    .tbl-last {
        max-width: 150px;
        padding: 0px 20px;
        text-align: right;
        justify-content: end;
        line-height: 30px;
        vertical-align: middle;
        align-items: center;

        img {
            width: 15px;
            cursor: pointer;
        }

        span {
            font-weight: 500;
            font-size: 16px;
            border-radius: 10px;
            text-align: center;
            padding: 0px 20px;
            color: var(--white-color);

            &.loading {
                background: #0061FF;
            }

            &.finished {
                background: var(--custom-color-3);
            }

            &.failed {
                background: var(--red-color);
            }
        }

    }

    .tbl-loading {
        width: 5%;
        padding: 0px 20px;
    }
}